import e1 from "../../assets/images/marketplace/employee_mp1.png";
import c1 from "../../assets/images/marketplace/company_mp2.png";
import e2 from "../../assets/images/application/employee_mc2.png";
import c2 from "../../assets/images/application/company_mc3.png";
import e3 from "../../assets/images/marketplace/employee_mp4.png";
import c3 from "../../assets/images/marketplace/company_mp3.png";
import c4 from "../../assets/images/marketplace/company_mp1.png";
import e4 from "../../assets/images/marketplace/employee_mp3.png";

import arrow from "../../assets/images/marketplace/test-arrow.png";
import employee_1 from "../../assets/images/application/employee_mc4.png";
import company_1 from "../../assets/images/application/company_mc4.png";
import employee_2 from "../../assets/images/application/employee_mc3.png";
import company_2 from "../../assets/images/application/company_mc2.png";
import employee_3 from "../../assets/images/application/employee_mc2.png";
import company_3 from "../../assets/images/application/company_mc3.png";
import employee_4 from "../../assets/images/application/employee_mc1.png";
import company_4 from "../../assets/images/application/company_mc1.png";

import energyLogo from "../../assets/images/csr/energyLogo.png";
import waterLogo from "../../assets/images/csr/waterLogo.png";
import emissionLogo from "../../assets/images/csr/emissionLogo.png";

export const headerTitleJSON = [
  { url: "/careers", text: "Career" },
  { url: "/culture", text: "Culture" },
  { url: "/events", text: "Events" },
  { url: "/our-address", text: "Addresses" },
  { url: "/privacy", text: "Privacy" },
  { url: "/terms-conditions", text: "Terms & Conditions" },
  { url: "/csr", text: "CSR" },
  { url: "/companyAnnualReport", text: "Company Annual Reports" },
  { url: "/marketplace", text: "MarketPlace" },
  {
    url: "/application",
    text: (
      <img
        src="https://img1.nowpurchase.com/assets/images/MetalCloudLogo.svg?height=40&fit=crop&auto=format,enhance&q=100"
        alt="Metal Cloud"
      />
    ),
  },
  { url: "/scrap-recycling", text: "Scrap Recycling" },
  {
    url: "/educational-empowerement",
    text: "CSR",
  },
];
export const counts = [
  {
    number: "39000",
    text: "+ Tonnes",
    title: "raw materials delivered",
  },
  {
    number: "150",
    text: "+ Clients",
    title: "savoured by our services",
  },
  {
    number: "100000",
    text: "+ Orders",
    title: "received and delivered",
  },
  {
    number: "98.1",
    text: "% Delivery",
    title: "success on 1st attempt",
  },
];

export const countCSR = [
  {
    number: "50000",
    text: "+ ",
    title: "Tonnes Scrap Supplied",
  },
  {
    number: "2000",
    text: "+ ",
    title: "Tonnes Processed at our Unit",
  },
  {
    number: "55000",
    text: "+ ",
    title: "Tonnes of Iron Ore Saved",
  },
];
export const countc = [
  {
    number: "98",
    text: "% Accurate",
    title: "Addition/Dilution Suggestion",
  },
  {
    number: "25",
    text: "% Reduction",
    title: "in Metallurgical Rejection",
  },
  {
    number: "10",
    text: "% Reduced",
    title: "Production Time",
  },
  {
    number: "6",
    text: "% Minimised",
    title: "Production Costs",
  },
];
export const countEnv = [
  {
    number: "17",
    text: "% decrease",
    title: " in energy consumption",
    src: energyLogo,
  },
  {
    number: "40",
    text: "% Reduction",
    title: " in Water Consumption",
    src: waterLogo,
  },
  {
    number: "58",
    text: "% mitigation",
    title: " of greenhouse gas emissions",
    src: emissionLogo,
  },
];
export const testimonials = [
  {
    quote: (
      <>
        <b>NowPurchase</b> has been a game-changer for our procurement process.
        The platform's efficiency and user-friendly interface have streamlined
        our purchasing workflow, saving us time and resources. Highly
        recommended!
      </>
    ),
    name: "Vijay Agarwal",
    empImg: e1,
    title: "Director",
    companyLogo: c1,
  },
  {
    quote: (
      <>
        We've significantly reduced melt time, thanks to the invaluable dilution
        suggestions. The MetalCloud platform is a game-changer for foundry
        processes.
      </>
    ),
    name: "Pradeep Bera",
    empImg: e2,
    title: "Foundry Head",
    companyLogo: c2,
  },
  {
    quote: (
      <>
        Thanks to <b>NowPurchase</b>, our journey to success has been nothing
        short of remarkable. Their customer-centric approach ensured that our
        goals became their goals. With personalized strategies and unwavering
        support.
      </>
    ),
    name: "Yogesh Joshi",
    empImg: e3,
    title: "CEO",
    companyLogo: c3,
  },

  {
    quote: (
      <>
        Quality, reasonable price, and timely delivery are important for me to
        work with a vendor. <b>NowPurchase</b> checks all these requirements for
        me. And they are just a call away whenever I need help.
      </>
    ),
    name: "Sikhar Jaiswal",
    empImg: e4,
    title: "Owner",
    companyLogo: c4,
  },
];

export const testimonialsMC = [
  {
    quote:
      "The MetalCloud is fantastic! Its user-friendly interface, instant data delivery to our phones, and insightful data presentation have all helped  make our work easier.",
    name: "Pankaj Phukan",
    empImg: employee_1,
    title: "MR Representative",
    companyLogo: company_1,
  },
  {
    quote:
      "MetalCloud has been a valuable addition! The automation,ChargeMix suggestions, and real-time WhatsApp integration have boosted efficiency and team collaboration. Truly outstanding.",
    name: "The Kolhapur Metals",
    empImg: employee_2,
    title: "MR Representative",
    companyLogo: company_2,
  },
  {
    quote:
      "We've significantly reduced melt time, thanks to the invaluable dilution suggestions. The MetalCloud platform is a game-changer for foundry processes.",
    name: "Pradeep Bera",
    empImg: employee_3,
    title: "Foundry Head",
    companyLogo: company_3,
  },

  {
    quote:
      " MetalCloud has truly transformed our operations! With 100% accurate chemistry corrections and valuable insights, it's been a real game-changer. Highly recommended!",
    name: "Prasad Mantri",
    empImg: employee_4,
    title: "Managing Director",
    companyLogo: company_4,
  },
];
