export const IMG_PREFIX =
  "https://image-upload-frontend-public.s3.amazonaws.com/";

export const imagePaths = {
  FinancingSolution: `${IMG_PREFIX}20240919110620_FinancingMainImg.png`,
  MarketPlaceMainImg: `${IMG_PREFIX}20240905052718_MarketPlaceMainImage.png`,
  MetalCloudAccuracy: `${IMG_PREFIX}20240905060054_MetalCloudSoftware_Accuracy.png`,
  MetalCloudAnalytics: `${IMG_PREFIX}20240905060032_MetalCloudSoftware_Analytics.png`,
  MetalCloudQualityReports: `${IMG_PREFIX}20240905055907_MetalCloudSoftware_QualityReport.png`,
  MetalCloudChargemix: `${IMG_PREFIX}20240905060008_MetalCloudSoftware_Chargemix.png`,
  MetalCloudIOT: `${IMG_PREFIX}20240905055941_MetalCloudSoftware_IOT.png`,
  MetalCloudWhatsapp: `${IMG_PREFIX}20240905055841_MetalCloudSoftware_Whatsapp.png`,
  MetalCloudSoftwareMainImg: `${IMG_PREFIX}20240915055637_MetalCloudMainContentImage.png`,
  ScrapProcessingMainImg: `${IMG_PREFIX}20240915060648_ScrapRecyclingMainContentImage.png`,
  PrivateLabelsMainImg: `${IMG_PREFIX}20240826082511_PrivateLabels_MainImage.png`,
  PrivateLabelsMobileMainImg: `${IMG_PREFIX}20240913055955_pvt_label_group.png`,
  TechSolutionsMainImg: `${IMG_PREFIX}20240905052846_TechSolutionsMainImage.png`,
  MarketPlaceVirginMetals: `${IMG_PREFIX}20240905054056_MarketPlace_VirginMetals.png`,
  MarketPlaceSand: `${IMG_PREFIX}20240905054122_MarketPlace_Sand.png`,
  MarketPlaceRefactoryItems: `${IMG_PREFIX}20240905054146_MarketPlace_RefactoryItems.png`,
  MarketPlacePigIron: `${IMG_PREFIX}20240905054210_MarketPlace_PigIron.png`,
  MarketPlaceNoduInoculant: `${IMG_PREFIX}20240905054351_MarketPlace_NoduInoculant.png`,
  MarketPlaceMetalScrap: `${IMG_PREFIX}20240905054424_MarketPlace_MetalScrap.png`,
  MarketPlaceFerroAlloys: `${IMG_PREFIX}20240905054454_MarketPlace_FerroAlloys.png`,
  MarketPlaceCarbonAdditives: `${IMG_PREFIX}20240905054528_MarketPlace_CarbonAdditives.png`,
  ScrapRecyclingSegregation: `${IMG_PREFIX}20240905055243_ScrapRecycling_Segregation.png`,
  ScrapRecyclingHighQualityScrap: `${IMG_PREFIX}20240905055318_ScrapRecycling_HighQualityScrap.png`,
  ScrapRecycling_Acquistion: `${IMG_PREFIX}20240905055342_ScrapRecycling_Acquistion.png`,
  ScrapRecyclingTestedQualityScrap: `${IMG_PREFIX}20240905065920_ScrapRecycling_TestedQualityScrap.png`,
  TechSolutions_Prices: `${IMG_PREFIX}20240905060437_TechSolutions_Prices.png`,
  FinancingSolutionSupplierIcon: `${IMG_PREFIX}20240918091254_supplychain.png`,
  FinancingSolutionRelationIcon: `${IMG_PREFIX}20240918091227_relation.png`,
  FinancingSolutionConsultationIcon: `${IMG_PREFIX}20240918093021_consultation.png`,
  FinancingSolutionContactIcon: `${IMG_PREFIX}20240918091202_contactdetails.png`,
  FundingMainImage: `${IMG_PREFIX}20240919055722_FundingMainImg.jpeg`,
  RealGroupLogo: `${IMG_PREFIX}20240919083222_RealGroupLogo.png`,
  AmtaunitImg: `${IMG_PREFIX}20240919094055_AmtaImg.png`,
};

export const servicesTabList = [
  {
    name: (
      <>
        Managed <br />
        MarketPlace
      </>
    ),
    icon: "20240822073934_market-place-active.png",
  },
  {
    name: (
      <>
        Scrap <br /> Recycling
      </>
    ),
    icon: "20240822073638_processed-scrap-active.png",
  },
  {
    name: (
      <>
        Flagship <br /> Range
      </>
    ),
    icon: "20240905092657_SignatureRangeIcon.png",
  },
  {
    name: (
      <>
        MetalCloud <br /> Platform
      </>
    ),
    icon: "20240822073817_metal-cloud-active.png",
  },
  {
    name: (
      <>
        Tech <br /> Enabled
      </>
    ),
    icon: "20240822074032_tech-solution-active.png",
  },
  {
    name: (
      <>
        Financing
        <br />
        Solution
      </>
    ),
    icon: "20240822074240_finance-solution-active.png",
  },
];

export const metalCloudTabs = [
  {
    text: (
      <>
        Live Heat Data on <strong>WhatsApp</strong>
      </>
    ),
    icon: imagePaths.MetalCloudWhatsapp,
  },
  {
    text: (
      <>
        Optimise <strong>ChargeMix</strong>
      </>
    ),
    icon: imagePaths.MetalCloudChargemix,
  },
  {
    text: (
      <>
        95% Accurate <strong>Suggestions</strong>
      </>
    ),
    icon: imagePaths.MetalCloudAccuracy,
  },
  {
    text: (
      <>
        <strong>Analytics</strong> of Your Heat Data
      </>
    ),
    icon: imagePaths.MetalCloudAnalytics,
  },
  {
    text: (
      <>
        <strong>IoT</strong> Enabled
      </>
    ),
    icon: imagePaths.MetalCloudIOT,
  },
  {
    text: (
      <>
        Material <strong>Quality Reports</strong>
      </>
    ),
    icon: imagePaths.MetalCloudQualityReports,
  },
];

export const metalCloudMobileTabs = [
  {
    text: (
      <>
        Live Heat Data <br /> on <strong>WhatsApp</strong>
      </>
    ),
    icon: imagePaths.MetalCloudWhatsapp,
    alignment: "row",
  },
  {
    text: (
      <>
        Optimise <strong>ChargeMix</strong>
      </>
    ),
    icon: imagePaths.MetalCloudChargemix,
    alignment: "row",
  },
  {
    text: (
      <>
        95% Accurate <br /> <strong>Suggestions</strong>
      </>
    ),
    icon: imagePaths.MetalCloudAccuracy,
    alignment: "row",
  },
  {
    text: (
      <>
        <strong>Analytics</strong> of Your <br /> Heat Data
      </>
    ),
    icon: imagePaths.MetalCloudAnalytics,
    alignment: "row",
  },
  {
    text: (
      <>
        <strong>IoT</strong> Enabled
      </>
    ),
    icon: imagePaths.MetalCloudIOT,
    alignment: "row",
  },
  {
    text: (
      <>
        Material <br /> <strong>Quality Reports</strong>
      </>
    ),
    icon: imagePaths.MetalCloudQualityReports,
    alignment: "row",
  },
];

export const ScrapRecyclingTabImages = [
  {
    text: (
      <>
        Robust <strong>Sourcing Network</strong>
      </>
    ),
    icon: imagePaths.ScrapRecycling_Acquistion,
  },
  {
    text: (
      <>
        50,000 sq. ft. <strong>Processing Hub</strong>
      </>
    ),
    icon: imagePaths.ScrapRecyclingHighQualityScrap,
  },
  {
    text: (
      <>
        Advanced Scrap <strong>Processing Machinery</strong>
      </>
    ),
    icon: imagePaths.ScrapRecyclingSegregation,
  },
  {
    text: (
      <>
        <strong>Tested & Certified</strong> Scrap Delivery
      </>
    ),
    icon: imagePaths.ScrapRecyclingTestedQualityScrap,
  },
];

export const MarketPlaceTabImages = [
  {
    text: (
      <>
        Metal <br /> Scrap
      </>
    ),
    icon: imagePaths.MarketPlaceMetalScrap,
  },
  {
    text: (
      <>
        Virgin <br /> Metals
      </>
    ),
    icon: imagePaths.MarketPlaceVirginMetals,
  },
  {
    text: (
      <>
        Carbon <br /> Additives
      </>
    ),
    icon: imagePaths.MarketPlaceCarbonAdditives,
  },
  {
    text: (
      <>
        Ferro <br /> Alloys
      </>
    ),
    icon: imagePaths.MarketPlaceFerroAlloys,
  },
  {
    text: (
      <>
        Nodularizers <br /> & Inoculants
      </>
    ),
    icon: imagePaths.MarketPlaceNoduInoculant,
  },
  {
    text: (
      <>
        Pig <br /> Iron
      </>
    ),
    icon: imagePaths.MarketPlacePigIron,
  },
  {
    text: (
      <>
        Refractory <br /> Items
      </>
    ),
    icon: imagePaths.MarketPlaceRefactoryItems,
  },
  {
    text: (
      <>
        Sand & <br /> Other Essentials
      </>
    ),
    icon: imagePaths.MarketPlaceSand,
  },
];

export const MarketPlaceMobileTabImages = [
  {
    text: (
      <>
        Metal <br /> Scrap
      </>
    ),
    icon: imagePaths.MarketPlaceMetalScrap,
  },
  {
    text: (
      <>
        Nodularizers <br /> & Inoculants
      </>
    ),
    icon: imagePaths.MarketPlaceNoduInoculant,
    alignment: "row",
  },
  {
    text: (
      <>
        Virgin <br /> Metals
      </>
    ),
    icon: imagePaths.MarketPlaceVirginMetals,
  },
  {
    text: (
      <>
        Pig <br /> Iron
      </>
    ),
    icon: imagePaths.MarketPlacePigIron,
  },
  {
    text: (
      <>
        Carbon <br /> Additives
      </>
    ),
    icon: imagePaths.MarketPlaceCarbonAdditives,
    alignment: "row",
  },
  {
    text: (
      <>
        Ferro <br /> Alloys
      </>
    ),
    icon: imagePaths.MarketPlaceFerroAlloys,
  },
  {
    text: (
      <>
        Refractory <br /> Items
      </>
    ),
    icon: imagePaths.MarketPlaceRefactoryItems,
    alignment: "row",
  },
  {
    text: (
      <>
        Sand & <br /> Other Essentials
      </>
    ),
    icon: imagePaths.MarketPlaceSand,
    alignment: "row",
  },
];

export const TechSolutionsTabImages = [
  {
    text: (
      <>
        <strong>Track Your Orders</strong> in Real-Time
      </>
    ),
    icon: imagePaths.ScrapRecyclingTestedQualityScrap,
  },
  {
    text: (
      <>
        <strong>AI-powered</strong> Instant Price <strong>Updates</strong>
      </>
    ),
    icon: imagePaths.TechSolutions_Prices,
  },
  {
    text: (
      <>
        <strong>24-Hour IoT Integration</strong> for Optimised Melting
      </>
    ),
    icon: imagePaths.MetalCloudIOT,
  },
  {
    text: (
      <>
        <strong>WhatsApp Bot</strong> for Sales Support
      </>
    ),
    icon: imagePaths.MetalCloudWhatsapp,
  },
];

export const FinancingSolutionsTabImages = [
  {
    text: (
      <>
        <strong>Smart</strong> Debt Advisory
      </>
    ),
    icon: imagePaths.FinancingSolutionConsultationIcon,
  },
  {
    text: (
      <>
        Streamlined <strong>Supply Chain</strong> Solutions
      </>
    ),
    icon: imagePaths.FinancingSolutionSupplierIcon,
  },
  {
    text: (
      <>
        Improved <strong>Supplier</strong> Relations
      </>
    ),
    icon: imagePaths.FinancingSolutionRelationIcon,
  },
  {
    text: (
      <>
        <strong>Strong</strong> Credit Network
      </>
    ),
    icon: imagePaths.FinancingSolutionContactIcon,
  },
];
