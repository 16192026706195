import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "../src/assets/css/style-responsive.css";
import "rsuite/dist/rsuite.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./Components/Home";
import Culture from "./pages/Culture";
import Careers from "./pages/Careers";
import Marketplace from "./pages/Marketplace";
import MetalcloudApplication from "./pages/MetalcloudApplication";
import GetInTouch from "./pages/GetInTouch";
import EventsPage from "./Components/Footer/Events";
import AddressSection from "./Components/Footer/Address";
import PrivacySection from "./Components/Footer/PrivacyPolicy";
import TermsConditions from "./Components/Footer/Terms&Conditions";
import CompanyAnnualReport from "./Components/Footer/CompanyAnnualReport";
import CSR from "./Components/Footer/CSR";
import TheCalcuttaProject from "./Components/Footer/CSR/SocialCorporateRes";
import GreenNarrativeOfScrap from "./Components/Footer/GreenNarrativeOfScrap";
import { useEffect, useState } from "react";
import { headerTitleJSON } from "./Components/constants";
import ReactGA from "react-ga4";
import NewsRoom from "./pages/NewsRoom";
import FundingRelease from "./pages/FundingRelease";

function App() {
  const headerTitle = headerTitleJSON.find(
    ({ url }) => url === window.location.pathname
  );

  useEffect(() => {
    if (headerTitle?.text) {
      document.title =
        "NowPurchase || " +
        (headerTitle?.url == "/application" ? "Metal Cloud" : headerTitle.text);
    } else document.title = "NowPurchase || Home";
  }, [headerTitle]);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-DPGLP1XHKS",
      },
    ]);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: headerTitle?.text,
    });
  }, []);

  return (
    <div className="home-template">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/culture" element={<Culture />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/application" element={<MetalcloudApplication />} />
          <Route path="/getInTouchForm" element={<GetInTouch />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/our-address" element={<AddressSection />} />
          <Route path="/privacy" element={<PrivacySection />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/newsroom" element={<NewsRoom />} />
          <Route
            path="/companyAnnualReport"
            element={<CompanyAnnualReport />}
          />
          <Route path="/csr" element={<CSR />} />
          <Route
            path="/educational-empowerement"
            element={<TheCalcuttaProject />}
          />
          <Route path="/scrap-recycling" element={<GreenNarrativeOfScrap />} />
          <Route
            path="/NowPurchase-raises-USD-6-million-in-funding-led-by-Infoedge-Ventures"
            element={<FundingRelease />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
